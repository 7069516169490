<template>
  <div style="margin-bottom: -1rem;" ref="table-search">
    <b-card-title v-if="false" class="font-weight-bolder">
      {{ $t('common.filter') }}
    </b-card-title>

    <b-row class="justify-content-start">
      <template v-for="item in fields">
        <b-col
          v-if="item.show === undefined || item.show === true"
          :col="typeof(item.col) === 'boolean' ? item.col : false"
          :cols="typeof(item.sm) === 'number' ? item.sm : 6"
          :md="typeof(item.md) === 'number' ? item.md : 4"
          :lg="typeof(item.lg) === 'number' ? item.lg : 3"
          class="row-item"
          :style="{ width: item.width ? item.width : 'auto' }"
        >
          <b-form-group :label="$t(item.label)">

            <template v-if="item.type === 'text'">
              <b-form-input :id="item.field" type="text" v-model="list[item.field]" class="font-size-16px"/>
            </template>

            <template v-if="item.type === 'select'">
              <v-select
                :id="item.field"
                :multiple="item.multiple"
                label="text"
                :options="typeof item.options === 'function' ? item.options() : item.options"
                v-model="list[item.field]"
                :reduce="text => text.value"
                :clearable="item.clearable !== false"
                :searchable="false"
                @input="changeField(item.field, list[item.field])"
                class="font-size-16px"
              >
                <template #no-options>
                  {{ $t('common.no_options') }}
                </template>
              </v-select>
            </template>

            <template v-if="item.type === 'date'">
              <flat-pickr :id="item.field" v-model="list[item.field]" class="form-control"/>
            </template>

            <template v-if="item.type === 'time'">
              <flat-pickr :id="item.field" v-model="list[item.field]" class="form-control"
                          :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
              />
            </template>

            <div v-if="item.type === 'date_range'" class="wh-form-range w-100">
              <div>
                <flat-pickr :id="item.field_start" v-model="list[item.field_start]" class="form-control w-100"/>
              </div>
              <div>{{ $t('common.date_to') }}</div>
              <div>
                <flat-pickr :id="item.field_end" v-model="list[item.field_end]" class="form-control w-100"/>
              </div>
            </div>
          </b-form-group>

        </b-col>
      </template>
      <b-col
        v-if="showBtn"
        class="d-flex justify-content-start align-items-end" cols="6" md="4" lg="3"
      >
        <b-form-group>
          <b-button
            variant="primary"
            @click="runSearch"
          >
            {{ $t('common.filter') }}
          </b-button>
        </b-form-group>
      </b-col>
      <!--      <b-col>-->
      <!--        <b-button variant="primary" class="mt-2 w-100 pl-0 pr-0" @click="clearSearchData">-->
      <!--          {{$t('common.clear')}}-->
      <!--        </b-button>-->
      <!--      </b-col>-->
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BButton, BRow, BCol, BCardTitle,BCardHeader
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// import _ from 'lodash'

export default {
  name: "TableSearch",
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BRow,
    BCol,
    flatPickr,
    BCardTitle,
    BCardHeader
  },
  props: {
    fields: {type: Array, default: []},
    default: {type: Object},
    showBtn: {type: Boolean, default: true}
  },
  data() {
    return {
      list: {},
      search_condition: {}
    }
  },
  mounted() {
    if (this.default) {
      this.list = JSON.parse(JSON.stringify(this.default))
    }
    // this.$refs['table-search'].querySelectorAll('button.vs__clear').forEach(elm => {
    //   elm.removeAttribute('title')
    // })
  },
  methods: {
    buildSearchCondition: function () {
      const tmp_list = JSON.parse(JSON.stringify(this.list))
      for (const i in tmp_list) {
        if (tmp_list[i] === null) {
          delete tmp_list[i]
        }
      }
      this.search_condition = JSON.parse(JSON.stringify(tmp_list))
    },

    runSearch: function () {
      this.$emit('runSearch')
    },
    clearSearchData: function () {
      this.list = {}
    },
    changeField: function (field, value) {
      this.$emit('changeField', field, value)
    },

  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.row-item {
  padding: 0 1rem;
}
.wh-form-range {
  > div {
    height: 38.54px;
  }
}
</style>
