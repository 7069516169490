<template>
  <div>
    <b-button variant="primary" @click="addBed">
      {{ $t('common.select') }}
    </b-button>
    <b-button v-if="options.length > 0" variant="secondary" class="ml-1" @click="clearBed">
      {{ $t('common.clear') }}
    </b-button>

    <div v-if="options.length > 0" class="pb-1 pl-1 pr-1 mt-1 group-edit">
      <b-row>
        <b-col cols="6" v-for="option in options" class="mt-1">
          <div class="group-edit p-6px">
            {{ option.bedNumber }}（{{ option.memberName }}）
            <feather-icon
              icon="XIcon" size="20" class="mt-4px cursor-pointer box-close"
              @click="deleteBed(option.bedID)"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <validation-observer v-if="slotConfig.name" :ref="'observer-' + slotConfig.name" tag="div">
      <validation-provider #default="{ errors }" :name="$t(slotConfig.label)" :rules="slotConfig.rule">
        <input
          v-model="options"
          type="hidden"
        />
        <small class="text-danger" v-show="options.length === 0">{{ errors[0] }}</small>
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'SlotBed',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    rowData: { type: Object },
    facilityId: { type: Number },
    slotConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: []
    }
  },
  watch: {
    facilityId: function (facilityID) {
      this.rowData.beds = []
      this.options = []
      this.$emit('changeBedFacilityId', facilityID)
    }
  },
  mounted() {
    this.options = this.rowData.beds
  },
  methods: {
    addBed: function () {
      this.$emit('addBed')
    },
    deleteBed: function (bed_id) {
      for (const i in this.options) {
        if (this.options[i].bedID === bed_id) {
          this.options.splice(i, 1)
          break
        }
      }
    },
    clearBed: function () {
      this.options = []
    },
    buildData: function () {
      this.rowData['bedIDs'] = []
      for (const i in this.options) {
        this.rowData['bedIDs'].push(this.options[i].bedID)
      }

      return true
    }
  }
}
</script>

<style scoped>

</style>
